<template>
    <div>
        <!--      头部      -->
        <bread></bread>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <div class="table-top">
                    <div class="iq-search-bar">
                        <el-form :model="searchForm" :inline="true">
                            <el-form-item>
                                <el-input v-model="Keyword" type="text" size="small"
                                    placeholder="姓名/手机号" @change="Search" clearable></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" size="small" @click="Search()">搜索</el-button>
                                <el-button type="primary" size="small" @click="AddUserBtn()" plain>新增</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <el-table :data="UserData" stripe style="width: 100%;" :header-cell-style="{background:'#F5F7FC'}">
                    <el-table-column prop="userName" label="姓名">
                    </el-table-column>
                    <el-table-column prop="phone" label="手机号">
                    </el-table-column>
                    <el-table-column prop="sexText" label="性别">
                    </el-table-column>
                    <el-table-column prop="role.roleName" label="角色">
                    </el-table-column>
                    <el-table-column prop="userStatuText" label="状态">
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" align="center">
                        <template slot-scope="scope">
                            <span class="info optionBtn"  @click="EditUserBtn(scope.row)">编辑</span>
                            <span class="success optionBtn"   v-show="scope.row.userStatu == -1" @click="EnableUser(scope.row)">启用
                            </span>
                            <span class="warning optionBtn"  v-show="scope.row.userStatu == 1" @click="DisableUser(scope.row)">停用
                            </span>
                            <span class="danger optionBtn"  @click="RemoveUser(scope.row)" plain>删除</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <!--    Dialog 新增    -->
        <el-dialog :title="DialogTitle" :visible.sync="ShowDialog" width="550px">
            <el-form class="dialog-form" :model="EditUserData" :rules="rules" ref="EditUserData" label-width="120px">
                <el-form-item label="姓名" prop="userName">
                    <el-input v-model="EditUserData.userName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="phone">
                    <el-input v-model="EditUserData.phone" autocomplete="off" :disabled="EditUserData.id>0"></el-input>
                    <p style="font-size:12px;color:#ccc">登录密码默认：123456</p>
                </el-form-item>
                <el-form-item label="性别" prop="sex">
                    <el-select v-model="EditUserData.sex" placeholder="性别">
                        <el-option label="男" :value="1"></el-option>
                        <el-option label="女" :value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="角色" prop="roleId">
                    <el-select v-model="EditUserData.roleId" clearable placeholder="请选择用户角色" :disabled="EditUserData.id>0">
                        <el-option v-for="item in RoleData" :key="item.id" :label="item.roleName" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancelDialog('EditUserData')">取 消</el-button>
                <el-button type="primary" @click="SaveUser('EditUserData')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import bread from "../../../components/common/breadcrumb";
import { User } from '../../../components/HospitalDomain/User';
import { Role } from '../../../components/HospitalDomain/Role.js';
export default {
    name: "UserManager",
    components: {
        bread
    },
    data() {
        var user = new User(this.TokenClient, this.Services.Authorization);
        var role = new Role(this.TokenClient, this.Services.Authorization);
        return {
            User: user,
            Role: role,
            Keyword: '',
            organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
            UserData: [],
            DialogTitle: '新增',
            ShowDialog: false,
            RoleData: [],
            searchForm:{},
            EditUserData: {
                "id": null,
                "userName": "",
                "password": "123456",
                "phone": "",
                "mail": "",
                "birthday": new Date(),
                "sex": 1,
                "departmentId": null,
                "userPostId": null,
                "userTitleId": null,
                "organizationId": null,
                "roleId": null,
                "userStatu": 1,

                "telephone": '',
                "address": "",
                "abstract": "",
                "regionId": null,
                "userQualificationId": null
            },
            rules: {
                userName: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                ],
                phone: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    { pattern: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/, message: "请输入合法手机号/电话号", trigger: "blur" }
                ],
                mail: [
                    { required: true, message: '请输入邮箱地址', trigger: 'blur' },
                    { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                ],
                birthday: [
                    { type: 'date', required: true, message: '请选择时间', trigger: 'blur' }
                ],
                roleId: [
                    { required: true, message: '请选择用户角色', trigger: 'blur' }
                ]
            },
            searchForm:{}
        }
    },
    mounted() {
        this.InitialUser();
        this.InitialRole();
    },
    methods: {
        InitialRole() {
            var _this = this;
            _this.Role.CateRoles('',false,false,
                function (data) {
                    _this.RoleData = data.data;
                },
                function (err) { console.log(err); });
        },
        InitialUser() {
            var _this = this;
            var userFilter = function (item) {
                switch (item.userStatu) {
                    case 1:
                        item.userStatuText = '正常';
                        break;
                    case 0:
                        item.userStatuText = '处理中';
                        break;
                    case -1:
                        item.userStatuText = '已禁用';
                        break;
                    case -2:
                        item.userStatuText = '已过期';
                        break;
                    case -3:
                        item.userStatuText = '已取消';
                        break;
                }
                item.sexText = item.sex == 1 ? '男' : '女';
                item.birthday = item.birthday.substr(0, 10);
                return item;
            };
            _this.User.Manager(_this.organizationId, _this.Keyword,
                function (data) {
                    _this.UserData = data.data.filter(userFilter);
                },
                function (err) { console.log(err); });

        },
        Search() {
            this.InitialUser();
        },
        AddUserBtn() {
            var _this = this;
            _this.DialogTitle = '新增用户';
            _this.EditUserData = {
                "id": 0,
                "userName": "",
                "password": "123456",
                "phone": "",
                "mail": "",
                "birthday": new Date(),
                "sex": 1,
                "departmentId": null,
                "userPostId": null,
                "userTitleId": null,
                "organizationId": _this.organizationId,
                "roleId": null,
                "userStatu": 1,
                "telephone": '',
                "address": "",
                "abstract": "",
                "regionId": null,
                "userQualificationId": null
            };
            _this.ShowDialog = true;
        },
        EditUserBtn(item) {
            var _this = this;
            _this.DialogTitle = '修改用户';
            // _this.EditUserData.id = item.id;
            // _this.EditUserData.userName = item.userName;
            // _this.EditUserData.phone = item.phone;
            // _this.EditUserData.mail = item.mail;
            // _this.EditUserData.birthday = item.birthday;
            // _this.EditUserData.sex = item.sex;
            // _this.EditUserData.departmentId = item.departmentId;
            // _this.EditUserData.userPostId = item.userPostId;
            // _this.EditUserData.userTitleId = item.userTitleId;
            // _this.EditUserData.organizationId = item.organizationId;
            // _this.EditUserData.organization = item.organization;
            // _this.EditUserData.roleId = item.roleId;
            // _this.EditUserData.telephone = item.telephone;
            // _this.EditUserData.address = item.address;
            // _this.EditUserData.abstract = item.abstract;
            // _this.EditUserData.regionId = item.regionId;
            // _this.EditUserData.userQualificationId = item.userQualificationId;

            _this.EditUserData.id = item.id;
            _this.EditUserData.roleId = item.roleId;
            _this.EditUserData.userTitleId = item.userTitleId;
            _this.EditUserData.departmentId = item.departmentId;
            _this.EditUserData.userName = item.userName;
            _this.EditUserData.sex = item.sex;
            _this.EditUserData.birthday = new Date(item.birthday);
            _this.EditUserData.userQualificationId = item.userQualificationId;
            _this.EditUserData.regionId = item.regionId;
            _this.EditUserData.address = item.address;
            _this.EditUserData.abstract = item.abstract;
            _this.EditUserData.mail = item.mail;
            _this.EditUserData.userPostId = item.userPostId;
            _this.EditUserData.doctorNo = item.doctorNo;
            _this.EditUserData.telephone = item.telephone;
            _this.EditUserData.photoFile = item.photoFile;
            _this.EditUserData.phone = item.phone;
            _this.EditUserData.organizationId = item.organizationId;
            _this.EditUserData.agentId = item.agentId;

            // _this.EditUserData.password = "123456";
            _this.ShowDialog = true;
        },
        SaveUser(formName) {
            var _this = this;
            var item = _this.EditUserData;
            
            console.log(item);
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (item.id) {
                        _this.User.Edit(_this.EditUserData,
                            function (data) {
                                _this.ShowDialog = false;
                                _this.InitialUser();
                            },
                            function (err) { console.log(err); });
                    }
                    else {
                        _this.User.Add(_this.EditUserData,
                            function (data) {
                                _this.ShowDialog = false;
                                _this.InitialUser();
                            },
                            function (err) {
                                var jsonObj = JSON.parse(err.msg);
                                if (jsonObj.code == "400") {
                                    _this.$message({
                                        type: 'error',
                                        message: jsonObj.msg
                                    });
                                } else {
                                    _this.ShowDialog = false;
                                }

                            });
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        cancelDialog(formName) {
            var _this = this;
            _this.ShowDialog = false;
            _this.OrganizationDialog = false;
            this.$refs[formName].resetFields();
        },
        EnableUser(item) {
            var _this = this;
            _this.User.EnableUser(item.id,
                function (data) {
                    item.userStatu = 1;
                    item.userStatuText = '正常';
                },
                function (err) { console.log(err); });
        },
        DisableUser(item) {
            var _this = this;
            _this.User.DisableUser(item.id,
                function (data) {
                    item.userStatu = -1;
                    item.userStatuText = '已禁用';
                },
                function (err) { console.log(err); });

        },
        RemoveUser(item) {
            var _this = this;
            console.log(item);
            _this.$confirm('确定删除该管理员?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                _this.User.Remove(item.id, function (data) {
                    _this.InitialUser();
                    _this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                }, function (err) { console.log(err); })
            }).catch(() => { });

        },
    }
}
</script>

<style scoped>

.navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.navbar {
    position: relative;
    height: 73px;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.header {
    flex: 1;
    text-align: right;
    margin-right: 15px;
}

.messages {
    margin-top: 13px;
    margin-right: 20px;
}

.messages .el-icon-bell {
    font-size: 26px;
}

/*content*/



.iq-search-bar {
    display: inline-block;
    margin-right: 20px;
}

.iq-search-bar .searchbox {
    width: 480px;
    position: relative;
}

.iq-search-bar .searchbox .search-link {
    position: absolute;
    right: 15px;
    top: 2px;
    font-size: 16px;
}

.iq-search-bar .search-input {
    width: 100%;
    padding: 5px 40px 5px 15px;
    border-radius: 10px;
    border: none;
    background: #cbdbf7;
    line-height: 1.8;
}

.dialog-form {
    width: 430px;
}

/**/
.addhospital {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.addhospital .el-form {
    flex: 1;
}

.addhospital .form-box {
    padding: 30px 50px 30px 30px;
    background: #ffffff;
    border-radius: 10px;
    overflow: hidden;
}

.demo-drawer__footer {
    display: flex;
    margin-left: 100px;
}

.headImg {
    position: relative;
    margin-bottom: 25px;
}

.headimgBox {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    margin: 0 auto;
    overflow: hidden;
    border: 5px solid rgba(12, 154, 154, 0.28);
}

.headimgBox img {
    width: 100%;
}

.headImg .upload-btn {
    position: absolute;
    left: 57%;
    bottom: 0px;
}
</style>
<style>
#user .el-drawer {
    background-color: #eff7f8;
}

.el-select-dropdown__wrap.el-scrollbar__wrap {
    overflow: scroll !important;
}
</style>
